var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"container"},[_c('barra-crud',{attrs:{"busca":false}}),_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-4 pr-0"},[_c('b-input-group',{staticClass:"mb-3"},[_c('b-form-input',{attrs:{"type":"date","placeholder":"DD/MM/YYYY","autocomplete":"off"},model:{value:(_vm.data),callback:function ($$v) {_vm.data=$$v},expression:"data"}})],1)],1),_c('div',{staticClass:"col-6 text-right pr-0 pl-1"},[_c('b-input',{attrs:{"placeholder":"Enviar notificação geral..."},model:{value:(_vm.obj.msg),callback:function ($$v) {_vm.$set(_vm.obj, "msg", $$v)},expression:"obj.msg"}})],1),_c('div',{staticClass:"col-2 text-left pl-1"},[_c('b-btn',{attrs:{"variant":"primary","block":""},on:{"click":_vm.enviar}},[_c('b-icon-paperclip'),_vm._v(" Enviar")],1)],1)]),_c('div',{staticClass:"row"},_vm._l((_vm.lista),function(not){return _c('div',{key:`notifi_${not.id}`,staticClass:"col-12 col-sm-6 mt-3 text-left"},[_c('div',{staticClass:"notificacao"},[_c('b-icon-paperclip',{staticClass:"icon"}),_c('div',{staticClass:"titulo"},[_vm._v(_vm._s(not.titulo))]),_c('div',{staticClass:"texto"},[_vm._v(_vm._s(not.texto))]),_c('div',{staticClass:"data"},[_vm._v(" "+_vm._s(not.usuario)+" - "+_vm._s(_vm._f("moment")(not.created_at,"HH:mm"))+" ")]),_c('div',{staticClass:"opts"},[_c('b-icon-eye',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.hover",modifiers:{"hover":true}}],attrs:{"title":not.visualizado
                .map((r) => `${r.usuario} - ${_vm.formatadata(r.created_at)}`)
                .join('\r\n')}}),_vm._v(" "+_vm._s(not.visualizado.length || 0)+" "),(!not.likes.some((x) => x.user_id == _vm.user.id))?_c('span',[_c('b-icon-heart',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.hover",modifiers:{"hover":true}}],attrs:{"title":not.likes
                  .map((r) => `${r.usuario} - ${_vm.formatadata(r.created_at)}`)
                  .join('\r\n')},on:{"click":function($event){return _vm.addResposta(not.id, 'like', true)}}}),_vm._v(" "+_vm._s(not.likes.length)+" ")],1):_vm._e(),(not.likes.some((x) => x.user_id == _vm.user.id))?_c('span',[_c('b-icon-heart-fill',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.hover",modifiers:{"hover":true}}],staticClass:"text-danger",attrs:{"title":not.likes
                  .map((r) => `${r.usuario} - ${_vm.formatadata(r.created_at)}`)
                  .join('\r\n')},on:{"click":function($event){return _vm.addResposta(not.id, 'like', true)}}}),_vm._v(" "+_vm._s(not.likes.length)+" ")],1):_vm._e(),_c('b-icon-chat-dots',{on:{"click":function($event){return _vm.abrirComentarios(not)}}}),_vm._v(" "+_vm._s(not.mensagens.filter((x) => x.tipo === "resposta").length)+" ")],1)],1)])}),0),(_vm.loading)?_c('div',{staticClass:"p-3 text-center"},[_c('b-spinner'),_c('br'),_vm._v(" aguarde... ")],1):_vm._e(),_c('b-modal',{attrs:{"id":"modal-comentarios","title":"Comentários","hide-footer":"","no-enforce-focus":""}},[_c('div',{staticClass:"chat"},[(
          _vm.resposta && _vm.resposta.notificacao && _vm.resposta.notificacao.mensagens
        )?_c('div',{staticClass:"list",attrs:{"id":"lstResp"}},_vm._l((_vm.resposta.notificacao.mensagens.filter(
            (x) => x.tipo == 'resposta'
          )),function(resp){return _c('div',{key:`resposta_${resp.id}`,staticClass:"mensagem"},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-4 usuario"},[_c('b-icon-chat-dots'),_vm._v(" "+_vm._s(resp.usuario)+" "),_c('br'),_c('small',[_vm._v(" "+_vm._s(_vm._f("moment")(resp.created_at,"DD/MM/YYYY HH:mm")))])],1),_c('div',{staticClass:"col-8"},[_vm._v(" "+_vm._s(resp.resposta)+" ")])])])}),0):_vm._e(),_c('div',{staticClass:"msg"},[_c('b-form',{attrs:{"autocomplete":"off"},on:{"submit":function($event){$event.preventDefault();return _vm.responder.apply(null, arguments)}}},[_c('b-input',{attrs:{"autocomplete":"off","name":"respComentsSS","id":"respComentSS"},model:{value:(_vm.resposta.msg),callback:function ($$v) {_vm.$set(_vm.resposta, "msg", $$v)},expression:"resposta.msg"}}),_c('b-btn',{attrs:{"variant":"info","type":"submit"}},[_c('b-icon-reply'),_vm._v(" Responder")],1)],1)],1)])])],1)
}
var staticRenderFns = []

export { render, staticRenderFns }