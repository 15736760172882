<template>
  <div class="container">
    <barra-crud :busca="false" />
    <div class="row">
      <div class="col-4 pr-0">
        <b-input-group class="mb-3">
          <b-form-input
            v-model="data"
            type="date"
            placeholder="DD/MM/YYYY"
            autocomplete="off"
          ></b-form-input>
        </b-input-group>
      </div>
      <div class="col-6 text-right pr-0 pl-1">
        <b-input v-model="obj.msg" placeholder="Enviar notificação geral..." />
      </div>
      <div class="col-2 text-left pl-1">
        <b-btn variant="primary" block @click="enviar"
          ><b-icon-paperclip /> Enviar</b-btn
        >
      </div>
    </div>

    <div class="row">
      <div
        class="col-12 col-sm-6 mt-3 text-left"
        v-for="not of lista"
        :key="`notifi_${not.id}`"
      >
        <div class="notificacao">
          <b-icon-paperclip class="icon" />
          <div class="titulo">{{ not.titulo }}</div>
          <div class="texto">{{ not.texto }}</div>
          <div class="data">
            {{ not.usuario }} - {{ not.created_at | moment("HH:mm") }}
          </div>
          <div class="opts">
            <b-icon-eye
              v-b-tooltip.hover
              :title="
                not.visualizado
                  .map((r) => `${r.usuario} - ${formatadata(r.created_at)}`)
                  .join('\r\n')
              "
            />
            {{ not.visualizado.length || 0 }}
            <span v-if="!not.likes.some((x) => x.user_id == user.id)">
              <b-icon-heart
                v-b-tooltip.hover
                :title="
                  not.likes
                    .map((r) => `${r.usuario} - ${formatadata(r.created_at)}`)
                    .join('\r\n')
                "
                @click="addResposta(not.id, 'like', true)"
              />
              {{ not.likes.length }}
            </span>
            <span v-if="not.likes.some((x) => x.user_id == user.id)">
              <b-icon-heart-fill
                class="text-danger"
                v-b-tooltip.hover
                :title="
                  not.likes
                    .map((r) => `${r.usuario} - ${formatadata(r.created_at)}`)
                    .join('\r\n')
                "
                @click="addResposta(not.id, 'like', true)"
              />
              {{ not.likes.length }}
            </span>
            <b-icon-chat-dots @click="abrirComentarios(not)" /> 
            {{not.mensagens.filter((x) => x.tipo === "resposta").length}}
          </div>
        </div>
      </div>
    </div>

    <div v-if="loading" class="p-3 text-center">
      <b-spinner /><br />
      aguarde...
    </div>

    <b-modal
      id="modal-comentarios"
      title="Comentários"
      hide-footer
      no-enforce-focus
    >
      <div class="chat">
        <div
          class="list"
          v-if="
            resposta && resposta.notificacao && resposta.notificacao.mensagens
          "
          id="lstResp"
        >
          <div
            class="mensagem"
            v-for="resp in resposta.notificacao.mensagens.filter(
              (x) => x.tipo == 'resposta'
            )"
            :key="`resposta_${resp.id}`"
          >
            <div class="row">
              <div class="col-4 usuario">
                <b-icon-chat-dots /> {{ resp.usuario }} <br />
                <small>
                  {{ resp.created_at | moment("DD/MM/YYYY HH:mm") }}</small
                >
              </div>
              <div class="col-8">
                {{ resp.resposta }}
              </div>
            </div>
          </div>
        </div>
        <div class="msg">
          <b-form @submit.prevent="responder" autocomplete="off" >
            <b-input v-model="resposta.msg" autocomplete="off" name="respComentsSS" id="respComentSS" />
            <b-btn variant="info" type="submit" 
              ><b-icon-reply /> Responder</b-btn
            >
          </b-form>
        </div>
      </div>
    </b-modal>
  </div>
</template>

<script>
import BarraCrud from "../components/common/BarraCrud.vue";
import moment from "moment";
export default {
  components: {
    BarraCrud,
  },
  sockets: {
    connect() {
      // console.log('conneccctionnn')
      // setTimeout(
      // this.solicitarNotificacoes, 1000);
    },
    listaNotificacoes(data) {
      // console.log("lista", data);
      this.lista = data.map((ret) => {
        return {
          ...ret,
          likes: ret.mensagens.filter((x) => x.tipo === "like"),
        };
      });
      if (this.resposta.notificacao) {
        let h = this.lista.find((x) => x.id === this.resposta.notificacao.id);
        if (h) {
          this.resposta.notificacao = h;
          this.$nextTick(()=>{
            document.querySelector("#lstResp").scrollTo = 9999;
          })
        }
      }
      this.loading = false;
    },
    solicitarListaNotificacoes() {
      this.solicitarNotificacoes();
    },
  },
  props: {},
  mounted() {
    // this.getNots();
    this.solicitarNotificacoes();
  },
  data() {
    return {
      loading: false,
      data: moment().format("YYYY-MM-DD"),
      obj: {
        msg: "",
      },
      lista: [],
      resposta: {
        notificacao: null,
        msg: "",
      },
    };
  },
  watch: {
    data: function () {
      this.solicitarNotificacoes();
    },
  },
  computed: {},
  methods: {
    formatadata(dt) {
      return moment(dt).format("DD/MM HH:mm");
    },
    async addResposta(id, tipo, resposta) {
      this.$socket.emit("notificacoes:addResposta", {
        user_id: this.user.id,
        tipo,
        resposta,
        notificacao_id: id,
      });
    },
    async solicitarNotificacoes() {
      this.loading = true;
      // console.log("------>", {
      //   data: moment(this.data).format("YYYY-MM-DD"),
      //   user_id: this.user.id,
      // });
      this.$socket.emit("notificacoes:getNotificacoes", {
        data: moment(this.data).format("YYYY-MM-DD"),
        user_id: this.user.id,
      });
    },
    async enviar() {
      if (!this.obj.msg || this.obj.msg.trim() == "") {
        this.$swal("Atenção", "Informe uma mensagem antes!", "error");
      } else {
        this.$socket.emit("notificacoes:addNotificacao", {
          user_id: this.user.id,
          titulo: "Comnunicação Geral",
          texto: this.obj.msg,
          tipo: "geral",
        });
        this.$swal({
          title: "Notificação enviada!",
          toast: true,
          timer: 1000,
          position: "top-right",
          icon: "success",
          showConfirmButton: false,
        });
        this.obj.msg = "";
      }
    },
    abrirComentarios(notifi) {
      this.resposta.notificacao = notifi;
      this.resposta.msg = "";
      this.$bvModal.show("modal-comentarios");
    },
    async responder() {
      if (!this.resposta.msg || this.resposta.msg.trim() == "") {
        this.$swal("Atenção", "Insira uma mensagem para enviar!", "error");
        return;
      }

      await this.addResposta(
        this.resposta.notificacao.id,
        "resposta",
        this.resposta.msg
      );

      this.resposta.msg = "";
    },
  },
};
</script>

<style lang="scss" scoped>
.chat {
  min-height: 400px;
  position: relative;
  .list {
    min-height: 370px !important;
    // overflow: hidden;
    // overflow-y: auto;
    .mensagem {
      width: 100%;
      border-bottom: 1px solid #ccc;
      padding: 10px;
      margin-bottom: 10px;
      .usuario {
        font-weight: bold;
      }
      .data {
        font-size: 10px;
        text-align: right;
        float: right;
      }
    }
  }
  .msg {
    input[type="text"] {
      width: calc(100% - 103px);
      display: inline;
      margin-right: 0px;
      border-radius: 0px;
      border-right: none;
      height: 32px;
    }
    button {
      margin-top: -5px;
    }
  }
}
.notificacao {
  background-color: #ffffff;
  min-height: 100px;
  padding: 5px;
  border: dotted 1px #ccc;
  margin: 10px;
  padding-left: 25px;
  overflow: hidden;
  .titulo {
    font-size: 14px;
    font-weight: bold;
  }
  .texto {
    font-size: 12px;
  }
  .data {
    position: absolute;
    text-align: right;
    bottom: 10px;
    right: 30px;
    font-size: 10px;
    font-weight: 300;
  }
  .opts {
    position: absolute;
    left: 20px;
    bottom: 15px;
    svg {
      margin-left: 10px;
      font-size: 17px;
      cursor: pointer;
      &:hover {
        text-shadow: 2px 2px 2px #ff0000;
      }
    }
  }
  .icon {
    position: absolute;
    font-size: 40px;
    color: rgb(55, 55, 55);
    left: 15px;
    top: -5px;
  }
}
</style>